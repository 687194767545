<style>
  .text {
    margin: 0;

    color: #091135;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: 1280px) {
    .text {
      font-size: 20px;
      line-height: 28px;
    }
  }
</style>

<p class="text">
  <slot>
    We regularly provide all the information you need, so you know that your
    digital assets are always fully backed, with maximum security and
    transparency. All the data is verified by CertiK, the most advanced formal
    verification technology for smart contracts and blockchain.
  </slot>
</p>
