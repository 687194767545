<style>
  .information {
    position: relative;

    width: 30px;
    height: 30px;
    margin-left: 8px;

    cursor: pointer;
  }

  .information:hover .information-tooltip {
    opacity: 1;

    pointer-events: all;

    transform: translateY(-50%) scale(1);
  }

  .information-tooltip {
    position: absolute;

    top: 50%;
    right: calc(100% + 20px);
    bottom: initial;

    width: 286px;

    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 6px 50px rgba(9, 17, 53, 0.1);

    opacity: 0;

    pointer-events: none;

    transform: translateY(-50%) scale(0.9);

    transition: all 0.3s ease-in-out;
  }

  .information-tooltipArrow {
    position: absolute;
    top: 50%;
    left: 100%;

    width: 16px;
    height: 16px;

    background: #ffffff;

    transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  }

  .information-content {
    position: relative;

    padding: 16px 12px;
  }

  @media (min-width: 1280px) {
    .information {
      width: 20px;
      height: 20px;
    }

    .information-tooltip {
      top: initial;
      bottom: calc(100% + 20px);
      left: 50%;

      width: 286px;

      transform: translateX(-50%) translateY(20px) scale(0.9);
    }

    .information:hover .information-tooltip {
      transform: translateX(-50%) translateY(0px) scale(1);
    }

    .information-tooltipArrow {
      top: initial;
      bottom: 0px;
      left: 50%;

      background: #ffffff;

      transform: translateX(-50%) translateY(50%) rotateZ(45deg);
    }
  }
</style>

<div class="information">
  <div class="information-tooltip">
    <div class="information-tooltipArrow" />

    <div class="information-content">
      <slot>
        The percentage of the total amount in circulation covered by our
        reserve. Certified by CertiK.
      </slot>
    </div>
  </div>

  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0002 0C8.93215 2.42737e-06 3.46184 3.65604 1.14095 9.26272C-1.17995 14.8694 0.105831 21.3221 4.39852 25.611C8.69121 29.8998 15.1451 31.1798 20.7497 28.8539C26.3543 26.528 30.0054 21.0544 30 14.9864C29.9852 6.71052 23.2761 0.00738255 15.0002 0Z"
      fill="#646464"
      fill-opacity="0.16"
    />
    <path
      d="M16.3275 12H13V13.9689H13.6706V21.0311H13V23H17V21.0311H16.3275V12Z"
      fill="#646464"
    />
    <path
      d="M13 9.00079C13 10.105 13.8958 11 15.0008 11C16.1042 11 17 10.105 17 9.00079C17 7.89584 16.1042 7 15.0008 7C13.8958 6.99921 13 7.89505 13 9.00079Z"
      fill="#646464"
    />
  </svg>
</div>
