<style>
  .title {
    margin: 0;

    color: #091135;

    font-family: 'Averta Std';
    font-size: 28px;
    font-weight: 700;
    line-height: 52px;
  }

  @media (min-width: 1280px) {
    .title {
      font-size: 40px;
      line-height: 72px;
    }
  }
</style>

<h2 class="title">
  <slot>Crystal clear</slot>
</h2>
