<style>
  .background {
    position: absolute;

    top: 0;
    left: 50%;

    max-height: 2311px;

    transform: translateX(-50%);
  }

  .background-desktop {
    position: absolute;
    top: -10%;
    left: 0;

    display: none;
  }

  @media (min-width: 670px) {
    .background {
      display: none;
    }

    .background-desktop {
      top: -56%;
      left: 50%;

      display: block;
      width: auto;
      height: 100%;
      min-height: 5553px;

      transform: translateX(-50%);
    }
  }

  @media (min-width: 1550px) {
    .background-desktop {
      top: 0%;
      left: 50%;

      width: 250%;
      height: auto;
      max-width: 6500px;
      min-width: 100vw;

      transform: translate(-50%, -26%);
    }
  }

  @media (min-width: 1800px) {
    .background-desktop {
      transform: translate(-50%, -28%);
    }
  }
</style>

<svg
  class="background"
  viewBox="0 0 383 2311"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M-147.982 -130.942C-134.572 -181.045 -97.1951 -229.6 -49.8264 -260.829L330.908 -480.895C381.607 -506.349 437.748 -515.698 492.344 -501.053L797.169 -419.283C851.764 -404.637 885.07 -346.885 870.441 -292.228L788.764 12.9423C774.135 67.5996 736.758 116.155 690.608 142.829L309.874 362.895C259.175 388.349 203.034 397.698 148.438 383.053L-156.387 301.283C-210.983 286.637 -244.288 228.885 -229.66 174.228L-147.982 -130.942Z"
    fill="#8BD9F5"
  />
  <path
    d="M-113.982 -130.362C-100.572 -180.409 -63.1951 -228.91 -15.8264 -260.105L364.908 -479.925C415.607 -505.351 471.748 -514.689 526.344 -500.06L831.169 -418.381C885.764 -403.752 919.07 -346.064 904.441 -291.468L822.764 13.3622C808.135 67.9586 770.758 116.46 724.608 143.105L343.874 362.925C293.175 388.351 237.034 397.69 182.438 383.06L-122.387 301.381C-176.983 286.752 -210.288 229.064 -195.66 174.468L-113.982 -130.362Z"
    fill="url(#paint0_linear)"
  />
  <path
    opacity="0.04"
    d="M-204.774 1817.35C-218.195 1767.28 -210.082 1706.57 -184.658 1655.85L35.1553 1274.99C66.35 1227.61 110.306 1191.44 164.913 1176.82L469.8 1095.15C524.407 1080.52 582.113 1113.85 596.753 1168.47L678.496 1473.44C693.137 1528.06 685.025 1588.77 658.381 1634.94L438.567 2015.8C407.372 2063.18 363.416 2099.35 308.81 2113.97L3.92236 2195.64C-50.6843 2210.27 -108.39 2176.94 -123.031 2122.32L-204.774 1817.35Z"
    fill="url(#paint2_linear)"
  />
  <defs>
    <linearGradient
      id="paint0_linear"
      x1="-199.109"
      y1="-507"
      x2="-199.109"
      y2="390"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#276CF7" />
      <stop offset="1" stop-color="#2744F7" />
    </linearGradient>
    <linearGradient
      id="paint2_linear"
      x1="-466.919"
      y1="1533.59"
      x2="-18.2004"
      y2="2310.79"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#091135" />
      <stop offset="1" stop-color="#404458" />
    </linearGradient>
  </defs>
</svg>

<svg
  viewBox="0 0 3396 5553"
  class="background-desktop"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity=".04"
    d="M900.539 905.637c51.399-89.043 147.931-162.818 254.711-198.291l842.45-225.867c110.2-22.705 222.57-11.78 319.67 44.292l542.14 313.066c97.1 56.072 131.29 183.743 75.22 280.883l-313.07 542.36c-56.07 97.14-152.6 170.91-254.71 198.29l-842.44 225.87c-110.21 22.7-222.58 11.78-319.68-44.3l-542.14-313.06c-97.099-56.07-131.289-183.75-75.218-280.89l313.067-542.353z"
    fill="url(#paint0_linear)"
  />
  <path
    opacity=".05"
    d="M1258.89 2629.8c127.74-34.24 282.64-13.54 412.04 51.32l971.68 560.81c120.89 79.59 213.16 191.73 250.47 331.05l208.36 777.85c37.31 139.32-47.72 286.54-187.07 323.89l-778.05 208.55c-139.35 37.36-294.25 16.66-412.03-51.32l-971.68-560.8c-120.889-79.59-213.16-191.73-250.477-331.05L293.78 3162.25c-37.317-139.32 47.713-286.54 187.065-323.9l778.045-208.55z"
    fill="url(#paint1_linear)"
  />
  <g opacity=".1">
    <circle cx="2076.37" cy="2242" r="2" fill="#2744F7" />
    <circle cx="2208.37" cy="2198" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="2198" r="2" fill="#2744F7" />
    <circle cx="2076.37" cy="2198" r="2" fill="#2744F7" />
    <circle cx="1988.37" cy="2198" r="2" fill="#2744F7" />
    <circle cx="1900.37" cy="2198" r="2" fill="#2744F7" />
    <circle cx="2208.37" cy="2154" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="2154" r="2" fill="#2744F7" />
    <circle cx="2076.37" cy="2154" r="2" fill="#2744F7" />
    <circle cx="1988.37" cy="2154" r="4" fill="#2744F7" />
    <circle cx="2208.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="2164.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="2076.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="2032.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="1988.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="1944.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="2252.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="2208.37" cy="2066" r="4" fill="#2744F7" />
    <circle cx="2164.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="2076.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="2032.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="1988.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="2164.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="2032.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="1988.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="1944.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="1900.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="1856.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="2208.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="2164.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="2076.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="2032.37" cy="1978" r="12" fill="#2744F7" fill-opacity=".24" />
    <circle
      cx="2032.37"
      cy="1978"
      r="13"
      stroke="#2744F7"
      stroke-opacity=".32"
      stroke-width="2"
    />
    <circle cx="2032.37" cy="1978" r="4" fill="#2744F7" />
    <path
      d="M2032.37 1978l88 88.18"
      stroke="url(#paint2_linear)"
      stroke-linecap="square"
    />
    <circle cx="1988.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="1944.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="1900.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="2252.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="2208.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="2076.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="2032.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="1988.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="1944.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="2252.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2208.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2164.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2120.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2076.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="1988.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="1900.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2208.37" cy="1846" r="2" fill="#2744F7" />
    <circle cx="2164.37" cy="1846" r="4" fill="#2744F7" />
    <circle cx="1988.37" cy="1846" r="2" fill="#2744F7" />
    <circle cx="1900.37" cy="1846" r="2" fill="#2744F7" />
    <circle cx="1812.37" cy="1846" r="2" fill="#2744F7" />
    <circle cx="1724.37" cy="1846" r="2" fill="#2744F7" />
    <circle cx="1680.37" cy="1846" r="2" fill="#2744F7" />
    <circle cx="1856.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="1812.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2340.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="2296.37" cy="2110" r="2" fill="#2744F7" />
    <circle cx="2340.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="2296.37" cy="2066" r="2" fill="#2744F7" />
    <circle cx="2340.37" cy="2022" r="4" fill="#2744F7" />
    <circle cx="2296.37" cy="2022" r="2" fill="#2744F7" />
    <circle cx="2384.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="2296.37" cy="1978" r="2" fill="#2744F7" />
    <circle cx="2340.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="2296.37" cy="1934" r="2" fill="#2744F7" />
    <circle cx="2340.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2296.37" cy="1890" r="2" fill="#2744F7" />
    <circle cx="2384.37" cy="1846" r="2" fill="#2744F7" />
    <circle cx="2296.37" cy="1846" r="2" fill="#2744F7" />
  </g>
  <path
    d="M998.243 747.397c26.597-99.312 100.747-195.557 194.717-257.46l755.28-436.21c100.57-50.455 211.94-68.986 320.24-39.956l604.7 162.083c108.3 29.03 174.37 143.505 145.35 251.846l-162.03 604.9c-29.02 108.34-103.16 204.59-194.71 257.46l-755.28 436.21c-100.57 50.46-211.94 68.99-320.25 39.96l-604.691-162.08c-108.303-29.03-174.373-143.51-145.353-251.85l162.027-604.903z"
    fill="#8BD9F5"
  />
  <path
    d="M1064.77 748.234c26.61-99.313 100.75-195.558 194.72-257.46l755.28-436.21c100.57-50.455 211.94-68.987 320.24-39.957l604.7 162.084c108.3 29.029 174.37 143.505 145.35 251.846l-162.03 604.903c-29.02 108.34-103.16 204.58-194.71 257.46l-755.28 436.21c-100.57 50.45-211.94 68.99-320.25 39.96l-604.69-162.09c-108.303-29.03-174.372-143.5-145.353-251.84l162.023-604.906z"
    fill="url(#paint3_linear)"
  />
  <defs>
    <linearGradient
      id="paint0_linear"
      x1="930.863"
      y1="140"
      x2="470.165"
      y2="1859.35"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#091135" />
      <stop offset="1" stop-color="#404458" />
    </linearGradient>
    <linearGradient
      id="paint1_linear"
      x1="1982.86"
      y1="1961"
      x2="0"
      y2="3105.81"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#091135" />
      <stop offset="1" stop-color="#404458" />
    </linearGradient>
    <linearGradient
      id="paint2_linear"
      x1="1988.37"
      y1="2022.09"
      x2="2076.55"
      y2="2110.09"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#2744F7" stop-opacity=".01" />
      <stop offset="1" stop-color="#fff" />
    </linearGradient>
    <linearGradient
      id="paint3_linear"
      x1="895.904"
      y1=".836"
      x2="895.904"
      y2="1780.84"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#276CF7" />
      <stop offset="1" stop-color="#2744F7" />
    </linearGradient>
  </defs>
</svg>
