<style>
  .card {
    position: relative;

    display: block;
    padding: 20px;

    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    box-shadow: 0px 0px 100px rgba(39, 68, 247, 0.05);
  }

  .card-header {
    padding-bottom: 20px;
  }

  .card-content {
    padding-top: 20px;
  }

  .card-border {
    position: relative;
    left: -20px;

    width: calc(100% + 40px);
    height: 1px;

    background: #e1e1e1;
  }

  @media (min-width: 1280px) {
    .card {
      padding-bottom: 0;

      border-bottom: none;
    }

    .card-border {
      display: none;
    }

    .card-header {
      padding-bottom: 0px;
    }

    .card-content {
      padding-top: 0px;
    }
  }
</style>

<div class="card">
  <div class="card-header">
    <slot name="header" />
  </div>
  <div class="card-border" />
  <div class="card-content">
    <slot name="content" />
  </div>
</div>
