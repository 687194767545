<style>
  .requestState {
    flex: 1;
    padding: 20px;

    background: #f9f9ff;
  }

  .requestState-row {
    display: flex;
    align-items: center;
  }

  .requestState-event {
    display: flex;
    margin-top: 20px;

    text-align: left;
  }

  .requestState-dot {
    position: relative;

    width: 13px;
    height: 13px;
    margin-top: 7px;
    margin-right: 12px;

    background: #2744f7;
    border-radius: 50%;
  }

  .requestState-line {
    position: absolute;
    top: 10px;
    left: 6px;

    width: 1px;
    height: calc(100% - 80px);

    background: #2744f7;
  }

  .requestState-eventSeparator {
    display: none;
  }

  .requestState-eventsList {
    position: relative;

    padding: 0;
    margin: 0;
  }

  .requestState-eventMeta {
    display: block;
  }

  .requestState-download {
    display: none;
  }

  .requestState-downloadMobile {
    margin-top: 28px;

    text-align: left;
  }

  @media (min-width: 1280px) {
    .requestState {
      flex: 1;
      padding: 20px 28px;
    }

    .requestState-header {
      display: flex;
      align-items: baseline;
    }

    .requestState-row {
      align-items: baseline;
    }

    .requestState-header .requestState-row {
      margin-right: 28px;
    }

    .requestState-eventDetails {
      display: flex;
      width: 100%;
    }

    .requestState-eventSeparator {
      display: block;
    }

    .requestState-dot {
      top: 6.5px;

      height: 13px;
      min-width: 13px;

      transform: translateY(-6.5px);
    }

    .requestState-line {
      top: 50%;

      height: calc(100% - 20px);

      transform: translateY(-50%);
    }

    .requestState-eventDetailsAction {
      flex: 2;
      margin-left: 60px;
    }

    .requestState-eventMeta {
      flex: 1;
      display: flex;
    }

    .requestState-download {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    .requestState-downloadMobile {
      display: none;
    }
  }
</style>

<script>
  import moment from 'moment';
  import accounting from 'accounting';

  import { EVENTS } from '../../api/index.svelte';

  import Bold from '../Atoms/Bold.svelte';
  import Label from '../Atoms/Label.svelte';
  import Link from '../Atoms/Link.svelte';
  import Number from '../Atoms/Number.svelte';

  export let tokenName;
  export let requestId;
  export let events = [];
  export let stateAfterFulfill;
</script>

<div class="requestState">
  {#if stateAfterFulfill}
    <div class="requestState-header">
      <div class="requestState-row">
        <Label alternate>In circulation:</Label>
        &nbsp;
        <Number small symbol={stateAfterFulfill.name}>
          {accounting.format(stateAfterFulfill.supply, 8).replace(/\.?0+$/, '')}
        </Number>
      </div>
      <div class="requestState-row">
        <Label alternate>Covered:</Label>
        &nbsp;
        <Number small>{stateAfterFulfill.reservePercentage}%</Number>
      </div>
      <div class="requestState-download">
        <Link href={`${"https://8emvlnm6ta.execute-api.us-east-2.amazonaws.com/production"}/report/${tokenName}/${requestId}`} alternate>
          Download certification
        </Link>
      </div>
    </div>
  {/if}

  <ul class="requestState-eventsList">
    {#each events as { name, txHash, timestamp }, i}
      <div class="requestState-event">
        <div class="requestState-dot" />
        {#if i === 0}
          <div class="requestState-line" />
        {/if}
        <div class="requestState-eventDetails">
          <div class="requestState-eventMeta">
            <Label alternate>{EVENTS[name]}</Label>
            <div class="requestState-eventSeparator">
              <Label alternate>&nbsp;→&nbsp;</Label>
            </div>
            <Label>{moment(timestamp, 'X').format('HH:mm - MMM D YYYY')}</Label>
          </div>
          <div class="requestState-eventDetailsAction">
            <Link external href={`https://etherscan.io/tx/${txHash}`} alternate>
              View transaction
            </Link>
          </div>
        </div>
      </div>
    {/each}
  </ul>

  <div class="requestState-downloadMobile">
    <Link href={`/report/${tokenName}/${requestId}`} alternate>
      Download certification
    </Link>
  </div>
</div>
