<style>
  .badge {
    box-sizing: border-box;
    padding: 12px 6px;

    background: #091135;
    color: #ffffff;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 28px;

    transform: rotateZ(180deg);
    writing-mode: vertical-lr;
  }

  .badge b {
    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 28px;
  }
</style>

<div class="badge">Hosted by <b>CertiK</b></div>
