<style>
  .logo {
    width: auto;
    height: 100%;
  }
</style>

<svg
  class="logo"
  viewBox="0 0 126 30"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M25.85 4.6l.16.95c.12.89.18 1.79.17 2.68 0 1.98-.24 3.96-.7 5.88a22.04 22.04 0 01-3.29 7.3 22.75 22.75 0 01-8.04 7.05l-.46.25-.46-.25c-3.72-2-6.27-4.5-8.04-7.05a22.04 22.04 0 01-3.28-7.3c-.47-1.92-.71-3.9-.71-5.88-.01-.9.05-1.79.17-2.68l.16-.95a.6.6 0 00-1.18-.2l-.16.96A19 19 0 000 8.23c0 2.08.25 4.15.75 6.17a23.24 23.24 0 003.46 7.7 23.93 23.93 0 008.46 7.42l.74.4c.18.1.4.1.57 0l.74-.4a24 24 0 008.46-7.42 23.24 23.24 0 003.46-7.7c.5-2.02.75-4.09.75-6.17 0-.96-.06-1.92-.2-2.87l-.15-.96a.6.6 0 10-1.18.2z"
    fill="#fff"
  />
  <path
    d="M4.8 3.95l8.9-2.72L22.77 4a.6.6 0 10.35-1.15L13.87.03a.6.6 0 00-.35 0L4.45 2.8a.6.6 0 00.35 1.14z"
    fill="#fff"
  />
  <path
    d="M.4 4.77l12.76 24.9a.6.6 0 001.06 0l7.77-14.54.01-.03 4.98-10.34a.6.6 0 00-1.08-.53l-4.97 10.34-7.22 13.53L1.47 4.23a.6.6 0 10-1.06.54z"
    fill="#fff"
  />
  <path
    d="M6.51 15.86h14.18a.6.6 0 100-1.2H6.5a.6.6 0 000 1.2zM46.06 10.7h-9.23a.6.6 0 00-.6.6v7.4c0 .33.27.6.6.6h9.23v1.26h-9.23a1.84 1.84 0 01-1.85-1.85v-7.4a1.84 1.84 0 011.85-1.86h9.23v1.24zM63.37 9.44v1.26H54.5v3.66h7.14v1.27H54.5v3.67h8.88v1.26H53.24V9.44h10.13z"
    fill="#fff"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M81.67 14.57V11.3h-.01a1.84 1.84 0 00-1.85-1.85h-9.26v11.1h1.26v-4.2l.6.06h4.07l3.47 4.13h1.65l-3.48-4.13h1.7a1.84 1.84 0 001.85-1.85zm-1.86.58h-7.4a.6.6 0 01-.42-.17.55.55 0 01-.18-.41V11.3c0-.33.27-.6.6-.6h7.4c.16 0 .31.06.42.18a.6.6 0 01.17.42v3.26a.58.58 0 01-.59.58z"
    fill="#fff"
  />
  <path
    d="M88.3 9.44h11.1v1.26h-4.91v9.86h-1.26V10.7H88.3V9.44zM106.13 20.55V9.45h1.25v11.1h-1.25zM124.05 9.44h1.6L121.01 15l4.66 5.55h-1.62l-4.14-4.92h-3.58v4.92h-1.26V9.45h1.27v4.91h3.58l4.13-4.92z"
    fill="#fff"
  />
</svg>
